import React from 'react'
import Gallery from 'components/Gallery'
import './Page.scss'

const CharterHook = ({ children }) => <div className='project'>

    <div className='container'>

        <div className='page-logo-container'>
            <img src='/includes/images/projects/charterhook/logo.png' className='page-logo' alt='CharterHook'/>

            <div className='badges'>
                <h2 className='fs-2'>Product Designer / Frontend Engineer</h2>
                <span className='badge'>Lead Design</span>
                <span className='badge'>Logo</span>
                <span className='badge'>Branding</span>
                <span className='badge'>JavaScript</span>
                <span className='badge'>React</span>
                <span className='badge'>PHP</span>
                <span className='badge'>Laravel</span>

                <div className='scroll-indicator'>
                    <i className="fa-light fa-arrow-down"></i>
                </div>
            </div>
        </div>

        <div className='row headliner'>

            <div className='col-lg-8'>

            <h2 className='fancy'>
                    CharterHook
                    <span className='subtitle'>Book the Perfect Boat Trip.</span>
                </h2>

                <p>The AirBnb for boats.
                    Focused on giving captains a social media and booking platform to build their audience.</p>
            </div>
        </div>

        <hr/>

        <div className='row mt-5'>
            <div className='col-lg-4'>
                <h3>Marketing Material</h3>
                <span className='badge'>Logo</span>
                <span className='badge'>Branding</span>
                <p>Stickers, shirts, business cards were all made for sales team going after Florida captains.</p>
            </div>

            <div className='col-lg-8'>

                <Gallery columns={2} images={[
                    {
                        src: '/includes/images/projects/charterhook/screen1.png',
                        width: 2878,
                        height: 4232
                    },
                ]}/>

            </div>
        </div>

        <div className='row'>
            <div className='col-lg-4'>
                <h3>Product Design & <br/>Frontend Development</h3>
                <p>Admin tools for charter captains and search for boat bookings.</p>
            </div>
            <div className='col-lg-8'>
                <Gallery columns={2} images={[
                    {
                        src: '/includes/images/projects/charterhook/screen2.png',
                        width: 2878,
                        height: 2308
                    },
                    {
                        src: '/includes/images/projects/charterhook/screen3.png',
                        width: 420,
                        height: 1817
                    },
                    {
                        src: '/includes/images/projects/charterhook/screen4.png',
                        width: 420,
                        height: 1817
                    }
                ]}/>
            </div>
        </div>

    </div>
</div>

export default CharterHook
