import React from 'react'
import Gallery from 'components/Gallery'
import './Page.scss'

const BenefitHub = ({ children }) => <div className='project'>

    <div className='container'>

        <div className='text-center page-logo-container'>
            <img
                src='/includes/images/projects/benefithub/benefithub-white.png'
                className='fillwhite page-logo'
                alt='BenefitHub'
            />

            <div className='badges'>
                <h2 className='fs-2'>UI / UX Manager / Product Design / Lead Frontend Engineer </h2>
                <span className='badge'>Lead UX</span>
                <span className='badge'>UI KIT</span>
                <span className='badge'>Product Design</span>
                <span className='badge'>JavaScript</span>
                <span className='badge'>React</span>
                <span className='badge'>Redux</span>
                <span className='badge'>Mobile</span>
                <span className='badge'>Localization</span>
                <span className='badge'>Analytics</span>
                <span className='badge'>WCAG</span>

                <div className='scroll-indicator'>
                    <i className='fa-light fa-arrow-down'></i>
                </div>
            </div>
        </div>

        <div className='row headliner'>

            <div className='col-lg-8'>

            <h2 className='fancy'>
                    BenefitHub
                    <span className='subtitle'>Employer Perks Platform</span>
                </h2>

                <p className='fs-2'>BenefitHub is the world's leading employee benefits portal and discount marketplace.
                    Now used by thousands of employers globally, including in the U.S., Canada, India and the U.K.,
                    they are one of the industry’s fastest-growing companies.</p>

                <p className='fs-2'> A White-labeled benefits + discounts platform for employers.
                    Used by employees of companies such as Amazon, Walmart, American Airlines and Target.</p>
            </div>
        </div>

        <div className='row mt-5'>
            <div className='col-lg-4'>
                <h3>Mobile App</h3>
                <div className='badge'>Design</div>
                <div className='badge'>Mobile</div>
                <div className='badge'>Adobe Xd</div>
                <p>
                    Mobile App on iOS and Android phones, built to showcase
                    offers and use geolocation to alert users to deals in their area.
                </p>
            </div>
            <div className='col-lg-8'>
                <Gallery
                    columns={2}
                    images={[
                        {
                            src: '/includes/images/projects/benefithub/mobile-launch-screen.png',
                            caption: 'BenefitHub - Mobile',
                            width: 260,
                            height: 300
                        },
                        {
                            src: '/includes/images/projects/benefithub/mobile-intro-screen.png',
                            caption: 'BenefitHub - Mobile',
                            width: 260,
                            height: 300
                        },
                        {
                            src: '/includes/images/projects/benefithub/mobile-restaurants-landing.png',
                            caption: 'BenefitHub - Mobile',
                            width: 260,
                            height: 300
                        },
                        {
                            src: '/includes/images/projects/benefithub/mobile-local-search.png',
                            caption: 'BenefitHub - Mobile',
                            width: 260,
                            height: 300
                        },
                        {
                            src: '/includes/images/projects/benefithub/mobile-deal-modal.png',
                            caption: 'BenefitHub - Mobile',
                            width: 260,
                            height: 300
                        },
                        {
                            src: '/includes/images/projects/benefithub/mobile-giftcard-modal.png',
                            caption: 'BenefitHub - Mobile',
                            width: 260,
                            height: 300
                        },
                    ]}/>
            </div>
        </div>

        <hr className='mt-5'/>

        <div className='row'>
            <div className='col-lg-4'>
                <h3>Web App Redesign</h3>
                <div className='badge'>React</div>
                <div className='badge'>Adobe Xd</div>
                <div className='badge' title='Progressive Web Application'>PWA</div>
                <div className='badge'>Desktop</div>
                <div className='badge'>Mobile</div>

                <p>Inspired by Amazon and Rakuten Marketplaces,
                    the application was overhauled to increase engagment.</p>

                <ul>
                    <li>Responsive Web App</li>
                    <li>Improved Localization Support</li>
                    <li>Streamlined Navigation</li>
                </ul>

            </div>
            <div className='col-lg-8'>
                <Gallery
                    columns={2}
                    images={[
                        {
                            src: '/includes/images/projects/benefithub/webapp-redesign-1.png',
                            caption: 'BenefitHub - WebApp',
                            width: 1680,
                            height: 916
                        }
                    ]}/>
            </div>
        </div>

        <div className='row mt-5'>
            <div className='col-lg-4'>
                <h3>Component Library & UI-Kit</h3>
                <div className='badge'>React</div>
                <div className='badge'>Adobe Xd</div>
                <p>
                    Created <a href='https://uikit.benefithub.dev' target='_blank' rel='noreferrer'>core component library</a> in
                    React.
                </p>
                <ul>
                    <li>Support for Cascading Theming</li>
                    <li>Form Components like Inputs, Select, Dropdowns</li>
                    <li>Page Components like Grid, Loaders, Cards, Badges, Progress Bars</li>
                </ul>

                <p>
                    <a href='https://uikit.benefithub.dev' target='_blank' className='border-button' rel='noreferrer'>
                        View UI KIT
                    </a>
                </p>
            </div>
            <div className='col-lg-8'>
                <Gallery
                    columns={2}
                    images={[
                        {
                            src: '/includes/images/projects/benefithub/uikit1.png',
                            caption: 'BenefitHub - UI Kit',
                            width: 1680,
                            height: 916
                        },
                        {
                            src: '/includes/images/projects/benefithub/uikit-loaders.png',
                            caption: 'BenefitHub - UI Kit - Loaders example',
                            width: 1306,
                            height: 593
                        }
                    ]}/>

            </div>
        </div>

    </div>
</div>

export default BenefitHub
