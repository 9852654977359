import { Routes, Route } from 'react-router-dom'

import Layout from 'layouts/PageLayout/PageLayout'

import Home from 'Routes/Home'
import Projects from 'Routes/Projects'

import 'styles/main.scss'
import 'App.css'

function App() {
  return (
    <Layout className="App">
        <Routes>
            <Route path='/' element={<Home/>}/>
            {/*<Route pathy='/projects/:projectId' element={Projects} />*/}
        </Routes>
        <Projects />
    </Layout>
)
}

export default App
