import React from 'react'
import './Page.scss'

const Reitz = ({ children }) => <div className='project'>

    <div className='container'>
        <div className='row'>
            <div className='col-lg-4 text-center mt-5'>
                <img src='/includes/images/projects/reitz/logo.png' className='project-logo' alt='Reitz Union - UF'/>
            </div>

            <div className='col-lg-8 mt-5'>

                <div className='pull-lg-right mt-5'>
                    <span className='badge'>ActionScript</span>
                    <span className='badge'>JavaScript</span>
                    <span className='badge'>Design</span>
                    <span className='badge'>PHP</span>
                    <span className='badge'>ASP</span>
                </div>

                <h2 className='fancy'>
                    Reitz Union
                    <span className='subtitle'>UF Student Union</span>
                </h2>
                <p>
                    My deep dark past, where my only tools were to use VBscript. <br/>
                    <em>The horror.</em>
                </p>

                <ul>
                    <li>Designed updates to the Leisure Course site provided by the Student Union</li>
                    <li>Converted sites from VB to using PHP backend.</li>
                    <li>Developed site for Greek life site for Fraternities and Sororities.</li>
                    <li>Built interactive building map of the Reitz Union using Flash.</li>
                </ul>

            </div>
        </div>
    </div>
</div>

export default Reitz
