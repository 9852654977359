import React from 'react'
import Gallery from 'components/Gallery'

import './Page.scss'

const SharpSpring = ({ children }) => <div className='project'>

    <div className='container'>

        <div className='page-logo-container'>
            <img src='/includes/images/projects/sharpspring/logo.png' className='page-logo' alt='SharpSpring'/>

            <div className='badges'>
                <h2 className='fs-2'>Director of UI / UX / Lead Product Design / Lead Frontend Engineer</h2>
                <span className='badge'>Branding</span>
                <span className='badge'>Product Design</span>
                <span className='badge'>Marketing</span>
                <span className='badge'>JavaScript</span>
                <span className='badge'>PHP</span>
                <span className='badge'>Localization</span>

                <div className='scroll-indicator'>
                    <i className="fa-light fa-arrow-down"></i>
                </div>
            </div>
        </div>

        <div className='row headliner'>

            <div className='col-lg-8'>

            <h2 className='fancy'>
                    SharpSpring
                    <span className='subtitle'>Marketing Automation</span>
                </h2>

                <p className='fs-2'>
                    SharpSpring is an all-in-one growth platform with a suite of tools for managing marketing and sales
                    with built-in automation and CMS and CRM capabilities.</p>

                <ul>
                    <li>CRM-like features: Leads and Opportunity modeling, Pipeline flows</li>
                    <li>Marketing Automation: web tracking, email editing</li>
                    <li>White-labeled web application and mobile experiences</li>
                </ul>

            </div>
        </div>

        <div className='row mt-5'>
            <div className='col-lg-4'>
                <h3>CMS</h3>
                <span className='badge'>Product Design</span>
                <span className='badge'>JavaScript</span>
                <p>A Content Management System for building highly extensible emails & landing pages.</p>
                <ul>
                    <li>Allows users to create highly customizable emails in a WYSIWYG editor.</li>
                    <li>Drag and drop editor with core marketing assets.</li>
                    <li>Allows team to build templates to simplify creation process.</li>
                </ul>
            </div>

            <div className='col-lg-8'>
                <Gallery
                    columns={2}
                    images={[
                        {
                            src: '/includes/images/projects/sharpspring/screen2.png',
                            caption: 'SharpSpring - Email Editor',
                            width: 1680,
                            height: 950
                        },
                        {
                            src: '/includes/images/projects/sharpspring/screen6.png',
                            caption: 'SharpSpring - Landing Pages',
                            width: 2696,
                            height: 1350
                        },
                    ]}/>
            </div>
        </div>

        <div className='row mt-5'>
            <div className='col-lg-4'>
                <h3>CRM - Sales Pipelines</h3>
                <span className='badge'>Product Design</span>
                <span className='badge'>JavaScript</span>
                <p>Customer Relationship Management tool that allows sales team to track their leads,
                    opportunities and business relationships from soup to nuts.</p>
            </div>

            <div className='col-lg-8'>
                <Gallery
                    columns={2}
                    images={[
                        {
                            src: '/includes/images/projects/sharpspring/screen7.png',
                            caption: 'SharpSpring - Dashboard',
                            width: 2876,
                            height: 1602
                        },
                        {
                            src: '/includes/images/projects/sharpspring/screen3.png',
                            caption: 'SharpSpring - Sales Pipeline',
                            width: 1600,
                            height: 718
                        }
                    ]}/>
            </div>
        </div>

        <div className='row mt-5'>
            <div className='col-lg-4'>
                <h3>Mobile App</h3>
                <span className='badge'>Product Design</span>
                <span className='badge'>JavaScript</span>
                <p>A pared-down app specifically for sales people
                    to be able to reach out to their leads via phone or email.</p>
            </div>

            <div className='col-lg-8'>
                <Gallery
                    columns={2}
                    images={[
                        {
                            src: '/includes/images/projects/sharpspring/screen4.png',
                            caption: 'SharpSpring - Mobile',
                            width: 800,
                            height: 600
                        }
                    ]}/>
            </div>
        </div>

        <div className='row mt-5'>
            <div className='col-lg-4'>
                <h3>Marketing Site & Collateral</h3>
                <span className='badge'>Branding</span>
                <span className='badge'>Product Design</span>
                <span className='badge'>Marketing</span>
                <p>Create B2B marketing website and brand materials</p>
                <ul>
                    <li>B2B Marketing Site for collecting sales leads</li>
                    <li>White-labeled web application and mobile experiences</li>
                </ul>
            </div>

            <div className='col-lg-8'>
                <Gallery
                    columns={2}
                    images={[
                        {
                            src: '/includes/images/projects/sharpspring/screen1.png',
                            caption: 'SharpSpring Homepage',
                            width: 1600,
                            height: 3500
                        },
                        {
                            src: '/includes/images/projects/sharpspring/screen5.png',
                            caption: 'SharpSpring - Dev Tee',
                            width: 440,
                            height: 600
                        },
                        {
                            src: '/includes/images/projects/sharpspring/screen8.png',
                            caption: 'SharpSpring - 500 Error Page',
                            width: 400,
                            height: 1150
                        },
                    ]}/>
            </div>
        </div>

    </div>
</div>

export default SharpSpring
