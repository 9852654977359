import React, {useState} from 'react'
import { NavLink, Link } from "react-router-dom"

import benefithub_logo from "Routes/Projects/assets/benefithub_logo.png"
import peerfit_logo from "Routes/Projects/assets/peerfit_logo.png"
import sharpspring_logo from "Routes/Projects/assets/sharpsrping_logo.png"

import rubysprite_logo from "Routes/Projects/assets/RubySprite-Logo.png"
import signals_logo from "Routes/Projects/assets/signals-logo.png"
import aria_logo from "Routes/Projects/assets/aria-help-icon.png"
import fox_shirt_logo from "Routes/Projects/assets/tee-shirt-fox-logo.png"
import spade_icon from "Routes/Projects/assets/Spade-Icon.png"
import rgb_icon from "Routes/Projects/assets/RGB-Icon.png"

import Horns from './assets/Horns'

import './About.scss'

const totalGreetings = 4

const About = () => {

    const [rando, setRando] = useState(0)

    const getRando = () => {
        const randoCalrizzian = ((rando + 1)  % totalGreetings) // (Math.ceil(Math.random() * totalGreetings))
        setRando(randoCalrizzian)
    }

    return <div id='me' className='page-about'>

        <div className='container'>

            <div className='row headliner'>

                <div className='col-lg-4 text-center'>
                    <img src='/includes/images/logo.png' className='initials' alt='My Initials - JK'/>
                </div>
                <div className='col-lg-8 mt-5'>
                    <div className='intro mt-5'>

                        <button className='hello' type='button' onClick={getRando}>
                            {rando === 0 && <h1 className='fancy'>{'Howdy '}
                                <span className='whole-word'>
                                    Buckar<span className='special-character'>oo<i
                                    className="fa-solid fa-hat-cowboy hat accent"/></span>
                                </span>
                            </h1>}

                            {rando === 1 && <h1 className='fancy'>
                                {'Well Speak of the '}
                                <span className='whole-word'>
                                    <span className='special-character'>D<span className='accent horns'><Horns
                                        className='hat'/></span></span>evil
                                </span>
                            </h1>}

                            {rando === 2 && <h1 className='fancy'>
                                {"What's Shaki"}
                                <span className='special-character'>n<span className='accent bacon'><i
                                    className='fa-solid fa-bacon'/></span></span>
                                {' Bacon'}
                            </h1>}

                            {rando === 3 && <h1 className='fancy'>
                                <span className='special-character'>A
                                    <span className='accent anchor'><i className='fa-solid fa-anchor'/></span>
                                </span>
                                {'hoy Matey!'}
                            </h1>}
                        </button>

                        <p className='intro'>
                            <strong className='fancy'>I am Joe Kelly,</strong> passionate product designer and
                            developer with over 10 years experience dedicated to building team equity by creating
                            products with purpose.
                        </p>

                        <a href='https://www.linkedin.com/in/josephlkelly/'
                           className='btn btn-xl btn-lg btn-outline-primary'>
                            <i className="fa-brands fa-linkedin-in"></i> <span>Connect on LinkedIn</span>
                        </a>
                        <a
                            href='/includes/downloads/CV-Nov-2024.pdf'
                            target='_blank'
                            className='btn btn-xl btn-lg btn-outline-primary'
                            rel='noreferrer'
                        >
                            <i className="fa-light fa-download"></i> <span>Download CV</span>
                        </a>
                    </div>
                </div>

                <div className='scroll-indicator'>
                    <i className="fa-regular fa-arrow-down"></i>
                </div>
            </div>

            <section className='row mt-5'>

                <h2 className='fancy'>About Me</h2>

                <div className='col-lg-10'>
                    <p> I am a wearer of many hats that has overseen the successful design and development of many Saas
                        products
                        over the years. I currently manage design and frontend development at <Link
                            to='/projects/benefithub'>BenefitHub</Link>,
                        where I continue to get my hands dirty.</p>

                    <p> A graduate from the University of Florida in the College of Engineering,
                        I spent years in the startup tech scene. While at <Link
                            to='/projects/grooveshark'>Grooveshark</Link>,
                        I oversaw the user interface of their globally popular music streaming app that served millions
                        of
                        people and billions of songs; developed an advertising and theming framework that helped sustain
                        the company financially.
                        I continued those successes at <Link to='/projects/sharpspring'>SharpSpring</Link> forming the
                        business in 2012 with the founders,
                        and worked tirelessly to design and create all their new products.
                        SharpSpring was successfully bought out in 2014 by SMTP and later Constant Contact.
                        I have also worked at <Link to='/projects/peerfit'>Peerfit</Link> working
                        on building their stellar progressive web app in React, a fitness solution for employers.
                    </p>
                </div>

                <div className='row mt-5'>
                    <div className='marketing col-lg-4'>
                        <h2 className='fancy mt-5'><i className="fa-light fa-chalkboard-user"></i> Leads</h2>
                        <ul>
                            <li>Project Leadership, Team Lead</li>
                            <li>Kanban, Scrum, Code + Design Review</li>
                            <li>Jira, Trello, Project Timelines</li>
                            <li>Mentorship</li>
                        </ul>
                    </div>

                    <div className='design col-lg-4'>

                        <h2 className='fancy mt-5'><i className="fa-light fa-paintbrush"></i> Designs</h2>
                        <ul>
                            <li>Adobe Suite: Xd, PhotoShop, Illustrator</li>
                            <li>Figma, Sketch</li>
                            <li>HTML, Sass, CSS Ninja</li>
                            <li>Accessibility / WCAG</li>
                            <li>Marketing Collateral</li>
                        </ul>
                    </div>

                    <div className='code col-lg-4'>

                        <h2 className='fancy mt-5'><i className="fa-light fa-code"></i> Codes</h2>
                        <ul>
                            <li>JavaScript, React, Redux</li>
                            <li>PHP, Laravel, Code Igniter</li>
                            <li>mySQL, Postgres, Firebase</li>
                            <li>Git resource control and policies</li>
                            <li>Localization</li>
                        </ul>
                    </div>
                </div>

            </section>

            <section className='row mt-5'>

                <div className='col-lg-12'>
                    <h2 className='fancy'>Featured Work</h2>
                </div>

                <div className='col-lg-4 mt-5'>
                    <NavLink to='/projects/benefithub' className='header-link'>
                        <img src={benefithub_logo} alt='BenefitHub Work' height={50}/>
                    </NavLink>
                    <div className='featured-project'>
                        <h3>BenefitHub</h3>
                        <p> A discounts platform for employers.
                            Used by employees of companies such as Amazon, Walmart, and Target.</p>
                    </div>
                    <Link to='/projects/benefithub' className='link'>
                        Check it out <i className="fa-light fa-arrow-right-long"></i>
                    </Link>
                </div>

                <div className='col-lg-4 mt-5'>
                    <NavLink to='/projects/peerfit' className='header-link'>
                        <img src={peerfit_logo} alt='Peerfit Work' height={50}/>
                    </NavLink>
                    <div className='featured-project'>
                        <h3>Peerfit</h3>
                        <p> Peerfit connected employers and carriers with personalized fitness experiences.
                            Offering a variety of classes that cater to all levels of fitness - using workplace wellness
                            dollars.
                        </p>
                    </div>
                    <Link to='/projects/peerfit' className='link'>
                        Check it out <i className="fa-light fa-arrow-right-long"></i>
                    </Link>
                </div>

                <div className='col-lg-4 mt-5'>
                    <NavLink to='/projects/sharpspring' className='header-link'>
                        <img src={sharpspring_logo} alt='SharpSpring Work' height={50}/>
                    </NavLink>
                    <div className='featured-project'>
                        <h3>SharpSpring</h3>
                        <p>SharpSpring is an all-in-one growth platform with a suite of tools for managing marketing and
                            sales with built-in automation and CMS and CRM capabilities.</p>
                    </div>
                    <Link to='/projects/sharpspring' className='link'>
                        Check it out <i className="fa-light fa-arrow-right-long"></i>
                    </Link>
                </div>

            </section>

            <section className='row mt-5'>

                <div className='col-lg-12'>
                    <h2 className='fancy'>Side Projects</h2>
                </div>

                <div className='col-lg-4'>
                    <img src={rubysprite_logo} alt='Board Games' height={50}/>
                    <h3>Ruby Sprite Games</h3>
                    <dl>
                        <dt><a href='https://conspiracy-game.com' target='_blank'
                               rel="noreferrer"><i class="fa-duotone fa-solid fa-user-secret"></i> conspiracy-game.com</a>
                        </dt>
                        <dd>Board game with an online companion with chat using Firebase.</dd>
                    </dl>
                </div>

                <div className='col-lg-4'>
                    <img src={signals_logo} alt='signals' height={50}/>
                    <h3>Project Management</h3>
                    <dl>
                        <dt><a href='https://medium.com/@josephlkelly/signals-21128b1d2bb4' target='_blank'
                               rel="noreferrer"><i class="fa-duotone fa-solid fa-infinity"></i> Signals</a></dt>
                        <dd>A way to build teams that focus on redundancy and knowledge share.
                        </dd>
                    </dl>
                </div>

                <div className='col-lg-4'>
                    <img src={aria_logo} alt='aria' height={50}/>
                    <h3>Accessibility</h3>
                    <dl>
                        <dt><a href='https://aria.help' target='_blank' rel="noreferrer"><i class="fa-regular fa-universal-access"></i> aria.help</a>
                        </dt>
                        <dd>Site to help guide beginners in making
                            their website more accessible for everyone.
                        </dd>
                    </dl>
                </div>

            </section>

            <section className='row mt-5'>

                <div className='col-lg-4'>
                    <img src={fox_shirt_logo} alt='Shirt Fox' height={50}/>
                    <h3>Tee-shirts</h3>
                    <dl>
                        <dt><a href='https://pixxid.threadless.com/' target='_blank' rel="noreferrer">
                            <i className="fa-light fa-shirt"></i> Threadless Store
                        </a></dt>
                        <dd>Shirts made for the threadless artist shops.</dd>
                    </dl>
                </div>

                <div className='col-lg-4'>
                    <img src={spade_icon} alt='Call a Spade a Spade' height={50}/>
                    <h3>Card Games</h3>
                    <dl>
                        <dt><a href='https://card-games-jk.netlify.app/' target='_blank' rel="noreferrer">
                            <i className="fa-regular fa-chess-queen"></i> BlackJack Game
                        </a></dt>
                        <dd>Black Jack Card Game for Chrome.</dd>
                    </dl>
                </div>

                <div className='col-lg-4'>
                    <img src={rgb_icon} alt='Shirt Fox' height={50}/>
                    <h3>Miscellaneous</h3>
                    <p>
                        <a href='https://medium.com/@josephlkelly' aria-label='My Medium' target='_blank'
                           rel="noreferrer">
                            <i className="fa-brands fa-medium"></i> Medium
                        </a>
                    </p>
                    <p>
                        <a href='https://codepen.io/dngrmice' target='_blank' rel="noreferrer">
                            <i className="fa-brands fa-codepen"></i> Code Pen
                        </a>
                    </p>
                </div>

            </section>
        </div>
    </div>
}

export default About
