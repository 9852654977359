import React from 'react'
import Gallery from 'components/Gallery'
import './Page.scss'

const Grooveshark = ({ children }) => <div className='project'>

    <div className='container'>

        <div className='page-logo-container'>
            <img src='/includes/images/projects/grooveshark/logo.png' className='page-logo' alt='Grooveshark'/>

            <div className='badges'>
                <h2 className='fs-2'>Lead Frontend Engineer</h2>
                <span className='badge'>Lead</span>
                <span className='badge'>JavaScript</span>
                <span className='badge'>ActionScript</span>
                <span className='badge'>Flex</span>
                <span className='badge'>Design</span>
                <span className='badge'>Ad Tech</span>

                <div className='scroll-indicator'>
                    <i className="fa-light fa-arrow-down"></i>
                </div>
            </div>
        </div>

        <div className='row mt-5'>

            <div className='col-lg-8'>

                <h2 className='fancy'>
                    Grooveshark
                    <span className='subtitle'>♪ Music Streaming Service ♪</span>
                </h2>

                <p className='fs-3'>
                    <strong><em>RIP. 2008 - 2015</em></strong><br/>
                    Ahead of its time. Grooveshark was a web-based music streaming service owned and operated by Escape
                    Media Group.
                    Users could upload digital audio files, which could then be streamed and organized in playlists.
                    The Grooveshark website had a search engine, music streaming features, and a music recommendation
                    system.
                </p>

                <p className='fs-3'>
                    Tons of talented people working collaboratively to create one great product.
                    Developed 3 iterations and eventually led the UI work on the main application.
                </p>
            </div>
        </div>

        <div className='row'>
            <div className='col-lg-4'>

                <h3>Music Library</h3>
                <div className='badge'>JavaScript</div>
                <div className='badge'>ActionScript</div>
                <ul>
                    <li>Allowed users to organize and curate their Grooveshark Library</li>
                    <li>Organized Playlists, Favorites, Albums and Artists</li>
                </ul>

            </div>

            <div className='col-lg-8'>

                <Gallery
                    columns={2}
                    images={[
                        {
                            src: '/includes/images/projects/grooveshark/screen1.png',
                            caption: 'Grooveshark - Music Library',
                            width: 1680,
                            height: 957
                        },
                        {
                            src: '/includes/images/projects/grooveshark/screen2.png',
                            caption: 'Grooveshark - Recs Engine',
                            width: 1680,
                            height: 957
                        },
                    ]}
                />

            </div>
        </div>

        <div className='row'>
            <div className='col-lg-4'>

                <h3>Theming Framework</h3>
                <div className='badge'>Design</div>
                <div className='badge'>JavaScript</div>
                <div className='badge'>ActionScript</div>
                <ul>
                    <li>Used in ad-related content and in-app take overs</li>
                    <li>Developed ads including BET, Rockband, Mazda, Trojan</li>
                    <li>Used for our yearly April Fools Day themes and VIP user themes</li>
                </ul>

            </div>

            <div className='col-lg-8'>

                <Gallery
                    columns={2}
                    images={[
                        {
                            src: '/includes/images/projects/grooveshark/screen3.png',
                            caption: 'Grooveshark - We Did Some R+D',
                            width: 1680,
                            height: 957
                        },
                        {
                            src: '/includes/images/projects/grooveshark/screen4.png',
                            caption: 'Grooveshark - April Fools Day',
                            width: 1680,
                            height: 957
                        }
                    ]}
                />

            </div>
        </div>

    </div>

</div>

export default Grooveshark
