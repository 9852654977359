import React, { Component } from 'react'
import Gallery from 'react-photo-album'
import { SlideshowLightbox } from 'lightbox.js-react'
import './Gallery.scss'

export default class GalleryBox extends Component {
    state = {
        lightboxIsOpen: false,
        currentImage: 0
    }

    toggleLightbox = (ev, photo = {}) => {
        console.warn(photo)
        this.setState({
            lightboxIsOpen: !this.state.lightboxIsOpen,
            currentImage: photo.index || 0
        })
    }

    nextImage = () => {
        let next = this.state.currentImage + 1
        if (next > this.props.images.length) {
            next = 0
        }

        this.setState({
            currentImage: next
        })
    }

    prevImage = () => {
        let prev = this.state.currentImage - 1
        if (prev < 0) {
            prev = this.props.images.length - 1
        }

        this.setState({
            currentImage: prev
        })
    }

    render () {
        const {
            lightboxIsOpen
        } = this.state
        const {
            images,
            margin = 5,
            ...props
        } = this.props

        const enableLightbox = false

        return <div className='gallery'>
            {images && images.map((image) => (
                <img className="w-full rounded showcase-image" width={image.width} src={image.src} alt={image.caption} />
            ))}

            <div className='gallery-set'>
                {enableLightbox && !!images && <Gallery
                    photos={images}
                    margin={margin}
                    onClick={this.toggleLightbox}
                    layout='masonry'
                    {...props}
                />}
            </div>

            {enableLightbox && !!images && <SlideshowLightbox
                open={lightboxIsOpen}
                theme="lightbox"
                showThumbnails
                className="container grid grid-cols-3 gap-2 mx-auto"
            />}
        </div>
    }
}
