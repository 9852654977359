import React from 'react'
import Gallery from 'components/Gallery'
import './Page.scss'

const Scout = ({ children }) => <div className='project'>

    <div className='container'>

        <div className='text-center page-logo-container'>
            <img
                src='/includes/images/projects/scout/Scout_Logo-Inverted.png'
                className='fillwhite w-full page-logo'
                alt='Scout'
            />

            <div className='badges'>
                <h2 className='fs-2'>Frontend Engineer / Advertising</h2>
                <span className='badge'>JavaScript</span>
                <span className='badge'>Google Maps</span>
                <span className='badge'>Geolocation</span>
                <span className='badge'>Advertising</span>

                <div className='scroll-indicator'>
                    <i className="fa-light fa-arrow-down"></i>
                </div>
            </div>
        </div>

        <div className='row headliner'>

            <div className='col-lg-8'>

            <h2 className='fancy'>
                    Scout
                    <span className='subtitle'>Smart & Mighty GPS Tracker</span>
                </h2>

                <p className='fs-2'> Whether tracking construction equipment, a ﬂeet of boats, or off-road vehicles,
                    Scout provides real-time visibility of all assets in the fleet.
                    Tracking can be done using the Scout Fleet Tracking platform, for use on any smart phone, tablet or desktop.</p>
            </div>
        </div>

        <div className='row mt-5'>
            <div className='col-lg-4'>
                <h3>Tracking Web App</h3>
                <div className='badge'>JavaScript</div>
                <div className='badge'>Google Maps</div>
                <p>Implemented a Web App to track scout devices and fleets.</p>
                <ul>
                    <li>Customized google maps to create historical tracking and device reporting.</li>
                    <li>Allow users to manage fleets and allow users to track add devices via QR codes.</li>
                </ul>
            </div>

            <div className='col-lg-8'>

                <Gallery
                    columns={2}
                    images={[
                        {
                            src: '/includes/images/projects/scout/Loading-Screen.png',
                            caption: 'Scout - Mobile',
                            width: 300,
                        },
                        {
                            src: '/includes/images/projects/scout/QR-Reader.png',
                            caption: 'Scout - Mobile',
                            width: 300,
                        },
                        {
                            src: '/includes/images/projects/scout/Mobile-Zones.png',
                            caption: 'Scout - Mobile',
                            width: 300,
                        },
                        {
                            src: '/includes/images/projects/scout/Settings.png',
                            caption: 'Scout - Mobile',
                            width: 300,
                        },
                    ]}/>

            </div>
        </div>

        <div className='row'>
            <div className='col-lg-4'>
                <h3>Advertising</h3>
                <div className='badge'>Photoshop</div>
                <p>Created IAB Units to target various audiences.</p>
            </div>

            <div className='col-lg-8'>

                <Gallery
                    columns={2}
                    images={[
                        {
                            src: '/includes/images/projects/scout/ads/300x600-Dogs.png',
                            caption: 'Scout - Ad - Dogs',
                            width: 300,
                        },
                        {
                            src: '/includes/images/projects/scout/ads/300x600-Kids.png',
                            caption: 'Scout - Ad - Kids',
                            width: 300,
                        },
                        {
                            src: '/includes/images/projects/scout/ads/300x600-Motorcycles.png',
                            caption: 'Scout - Mobile',
                            width: 300,
                        },
                        {
                            src: '/includes/images/projects/scout/ads/300x600-Kids-Dogs.png',
                            caption: 'Scout - Mobile',
                            width: 300,
                        },

                        {
                            src: '/includes/images/projects/scout/ads/640x100-Mobile1.png',
                            caption: 'Scout - Ad - Color',
                            width: 640,
                        },
                        {
                            src: '/includes/images/projects/scout/ads/640x100-Mobile2.png',
                            caption: 'Scout - Ad - Color',
                            width: 640,
                        },

                    ]}/>

            </div>
        </div>

    </div>
</div>

export default Scout
