import React, { Fragment, useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import throttle from 'lodash.throttle'
import './Header.scss'

import benefithub_logo from 'Routes/Projects/assets/benefithub_logo.png'
import peerfit_logo from 'Routes/Projects/assets/peerfit_logo.png'
import gs_logo from 'Routes/Projects/assets/grooveshark_logo.png'
import sharpspring_logo from 'Routes/Projects/assets/sharpsrping_logo.png'
import uf_logo from 'Routes/Projects/assets/uf_logo.png'
import bugjar_logo from 'Routes/Projects/assets/bugjar_logo.png'
import charterhook_logo from 'Routes/Projects/assets/charterhook_logo.png'
import scout_logo from 'Routes/Projects/assets/scout_logo.png'

const fullscreenWidth = 1600

const Header = () => {

    const location = useLocation()
    const [width, setWidth] = useState(window.innerWidth)

    const isDesktop = (width > fullscreenWidth)
    const [isOpen, setIsOpen] = useState(false)
    const now = new Date()

    useEffect(() => {
        window.addEventListener('resize', throttle(() => {
            setWidth(window.innerWidth)
        }, 200))
    })

    useEffect(() => {
        document.body.scrollIntoView({ behavior: 'smooth' })
        setIsOpen(false)
    }, [location])

    return <Fragment>

        {!isDesktop && <button aria-label={isOpen ? 'Close Menu' : 'Open Menu'} onClick={() => { setIsOpen(!isOpen) }} className={`menu-toggle ${isOpen ? 'open' : 'closed'}`} type='button'>
            {isOpen
                ? <i className="fa-light fa-xmark"/>
                : <i className="fa-light fa-bars" />
            }
        </button>}

        <header className={`header ${(isOpen || isDesktop) ? 'open' : ''}`}>

            <section className='about'>
                <div className='avatar'>
                    <img src='/includes/images/avatar-600.png' width='300' alt='Avatar of Joe Kelly' />
                </div>

                <h2 className='fancy'>Joe Kelly</h2>

                <h5>Product Designer + Developer</h5>
                <div className='progress'>
                    <div
                        className='progress-bar'
                        role='progressbar'
                        style={{ width: (new Date().getMonth() / 12) * 100 + '%' }}
                        aria-valuenow='25'
                        aria-valuemin='0'
                        aria-valuemax='100'
                    />
                </div>

                <nav className='projects'>
                    <NavLink to='/' exact className='header-link'>
                        <img src='/includes/images/logo.png' alt='About Me' />
                    </NavLink>

                    <NavLink to='/projects/benefithub' className='header-link'>
                        <img src={benefithub_logo} alt='BenefitHub Work' />
                    </NavLink>

                    <NavLink to='/projects/peerfit' className='header-link'>
                        <img src={peerfit_logo} alt='Peerfit Work' />
                    </NavLink>

                    <NavLink to='/projects/charterhook' className='header-link'>
                        <img src={charterhook_logo} alt='CharterHook Work' />
                    </NavLink>

                    <NavLink to='/projects/sharpspring' className='header-link'>
                        <img src={sharpspring_logo} alt='SharpSpring Work' />
                    </NavLink>

                    <NavLink to='/projects/grooveshark' className='header-link'>
                        <img src={gs_logo} alt='Grooveshark Work' />
                    </NavLink>

                    <NavLink to='/projects/scout' className='header-link'>
                        <img src={scout_logo} alt='Scout Work' />
                    </NavLink>

                    <NavLink to='/projects/bugjar' className='header-link'>
                        <img src={bugjar_logo} alt='Bugjar Work' />
                    </NavLink>

                    <NavLink to='/projects/reitz' className='header-link'>
                        <img src={uf_logo} alt='UF Work' />
                    </NavLink>
                </nav>

                <hr />

                <nav className='external'>
                    <a href='https://www.linkedin.com/in/josephlkelly' aria-label='My Linked In' className='symbol' target='_blank' rel="noreferrer">
                        <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                    <a href='https://dribbble.com/hotspacejustice' aria-label='My Dribbble' className='symbol' target='_blank' rel="noreferrer">
                        <i className="fa-brands fa-dribbble"></i>
                    </a>
                    <a href='https://medium.com/@josephlkelly' aria-label='My Medium' target='_blank' rel="noreferrer">
                        <i className="fa-brands fa-medium"></i>
                    </a>
                    <a href='https://github.com/dngrmice' aria-label='My Github' className='symbol' target='_blank' rel="noreferrer">
                        <i className="fa-brands fa-github"></i>
                    </a>
                </nav>

                <a className='cv-download' href='/includes/downloads/CV-Nov-2024.pdf' target='_blank' rel='noreferrer'>
                    <i className="fa-duotone fa-solid fa-user-tie" />
                    Download CV
                </a>

            </section>

            <section className='copyright'>
                © {now.getFullYear()} Joseph Kelly
            </section>

        </header>
    </Fragment>
}

export default Header
