import React from 'react'
import Header from 'components/Header'
import { useLocation } from 'react-router-dom'

import './PageLayout.scss'

const PageLayout = ({ children = null }) => {

    const location = useLocation()
    const pageClasses = location.pathname.split('/').join(' ')

    return <div className={`page-layout`}>

        <Header />

        <main className={`page ${pageClasses}`}>
            {children}
        </main>

    </div>
}

export default PageLayout
