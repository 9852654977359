import React from 'react'
import Gallery from 'components/Gallery'
import './Page.scss'

const Bugjar = ({ children }) => <div className='project'>

    <div className='container'>
        <div className='text-center page-logo-container'>
            <img src='/includes/images/projects/bugjar/logo.png' className='fillwhite w-full page-logo' alt='Bugjar'/>

            <div className='badges'>
                <h2 className='fs-2'>Product Design / Frontend Engineer</h2>
                <span className='badge'>Logo</span>
                <span className='badge'>Branding</span>
                <span className='badge'>Produt Design</span>
                <span className='badge'>JavaScript</span>
                <span className='badge'>Backbone</span>
                <span className='badge'>Highcharts</span>

                <div className='scroll-indicator'>
                    <i className="fa-light fa-arrow-down"></i>
                </div>
            </div>
        </div>

        <div className='row'>

            <div className='col-lg-8'>
            <h2 className='fancy'>
                    Bugjar
                    <span className='subtitle'>Bug tracking software</span>
                </h2>
                <p className='fs-3'>
                    A startup with former Grooveshark employees.
                    The application ran as an SDK that tracked bug reports and crashes from mobile and web applications.
                </p>
            </div>
        </div>

        <div className='row mt-5'>
            <div className='col-lg-4'>
                <h3>Branding</h3>
                <div className='badge'>Logo</div>
                <div className='badge'>Design</div>
                <p>Print and web materials created for attending conferences and events.</p>
            </div>

            <div className='col-lg-8'>
                <Gallery
                    columns={2}
                    images={[
                        {
                            src: '/includes/images/projects/bugjar/screen1.png',
                            caption: 'Bugjar - Business Card',
                            width: 500,
                            height: 682
                        },
                        {
                            src: '/includes/images/projects/bugjar/screen5.png',
                            caption: 'Bugjar - Home Page',
                            width: 810,
                            height: 1028
                        }
                    ]}
                />
            </div>
        </div>

        <div className='row'>
            <div className='col-lg-4'>
                <h3>Bug Reports</h3>
                <div className='badge'>JavaScript</div>
                <div className='badge'>Backbone</div>
                <div className='badge'>Highcharts</div>
                <p>Provided valuable information for mobile app crashes including stack traces and frequency of
                    issues.</p>
            </div>

            <div className='col-lg-8'>
                <Gallery
                    columns={2}
                    images={[
                        {
                            src: '/includes/images/projects/bugjar/screen4.png',
                            caption: 'Bugjar - Project Log',
                            width: 400,
                            height: 618
                        },
                        {
                            src: '/includes/images/projects/bugjar/screen6.png',
                            caption: 'Bugjar - App Dashboard',
                            width: 400,
                            height: 547
                        },
                        {
                            src: '/includes/images/projects/bugjar/screen2.png',
                            caption: 'Bugjar - Bug report',
                            width: 400,
                            height: 392
                        },
                        {
                            src: '/includes/images/projects/bugjar/screen3.png',
                            caption: 'Bugjar - Stack Trace',
                            width: 400,
                            height: 357
                        }
                    ]}
                />
            </div>
        </div>

    </div>
</div>

export default Bugjar
