// import Projects from './components/Page'
import { Routes, Route, Outlet } from 'react-router-dom'

import BenefitHub from './BenefitHub'
import Peerfit from './Peerfit'
import CharterHook from './CharterHook'
import SharpSpring from './SharpSpring'
import Grooveshark from './Grooveshark'
import Bugjar from './Bugjar'
import Scout from './Scout'
import Reitz from './Reitz'

const ProjectWrapper = () => <div className='project-page'>
    <Outlet />
</div>

const Projects = () => <Routes>
    <Route path='/projects' element={<ProjectWrapper />}>
        <Route path='BenefitHub' element={<BenefitHub/>}/>
        <Route path='Peerfit' element={<Peerfit/>}/>
        <Route path='CharterHook' element={<CharterHook/>}/>
        <Route path='SharpSpring' element={<SharpSpring/>}/>
        <Route path='Grooveshark' element={<Grooveshark/>}/>
        <Route path='Scout' element={<Scout/>}/>
        <Route path='Bugjar' element={<Bugjar/>}/>
        <Route path='Reitz' element={<Reitz/>}/>
    </Route>
</Routes>

export default Projects
