import React from 'react'
import Gallery from 'components/Gallery'
import './Page.scss'

const Peerfit = ({ children }) => <div className='project'>

    <div className='container'>

        <div className='text-center page-logo-container'>
            <img
                src='/includes/images/projects/peerfit/logo.svg'
                className='fillwhite w-full page-logo'
                alt='Peerfit'
            />

            <div className='badges'>
                <h2 className='fs-2'>Sr. Frontend Engineer</h2>
                <span className='badge'>JavaScript</span>
                <span className='badge'>React</span>
                <span className='badge'>Redux</span>
                <span className='badge'>Google Maps</span>
                <span className='badge'>Stripe</span>
                <span className='badge'>WCAG</span>
                <span className='badge'>Accessibility</span>

                <div className='scroll-indicator'>
                    <i className="fa-light fa-arrow-down"></i>
                </div>
            </div>
        </div>

        <div className='row headliner'>

            <div className='col-lg-8'>

            <h2 className='fancy'>
                    Peerfit
                    <span className='subtitle'>Fitness Solution</span>
                </h2>

                <p className='fs-2'> Peerfit connects employers and carriers with personalized fitness experiences.
                    Through their digital platform, insurance carriers,
                    brokers and employers can redefine their benefits programs by giving employees
                    access to a network of fitness studios and gyms -
                    offering a variety of classes that cater to all levels of fitness -
                    using workplace wellness dollars.</p>
            </div>
        </div>

        <div className='row mt-5'>
            <div className='col-lg-4'>
                <h3>Fitness Planning & Scheduling</h3>
                <div className='badge'>React</div>
                <div className='badge'>Redux</div>
                <div className='badge'>Google Maps</div>
                <div className='badge'>Stripe</div>
                <p>Implemented Class Search and Scheduling for local fitness gyms.</p>
                <ul>
                    <li>Subscriptions handled and maintained via stripe.</li>
                    <li>Worked with mobile developers to port features to new iOS and Android applications.</li>
                </ul>
            </div>

            <div className='col-lg-8'>

                <Gallery
                    columns={2}
                    images={[
                        {
                            src: '/includes/images/projects/peerfit/search1.png',
                            caption: 'Peerfit - Explore',
                            width: 1680,
                            height: 950
                        }
                    ]}/>

            </div>
        </div>

        <div className='row'>
            <div className='col-lg-4'>
                <h3>Marketing Site</h3>
                <div className='badge'>React</div>
                <div className='badge'>CSS</div>
                <p>Worked closely with design team to implement revamped marketing website.</p>
            </div>

            <div className='col-lg-8'>

                <Gallery
                    columns={2}
                    images={[
                        {
                            src: '/includes/images/projects/peerfit/homepage1.png',
                            caption: 'Peerfit - Homepage',
                            width: 1680,
                            height: 2641
                        }
                    ]}/>

            </div>
        </div>

    </div>
</div>

export default Peerfit
